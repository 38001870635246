import { AfterViewInit, Component, ElementRef, HostBinding, Input, NgZone, OnChanges, OnInit, SimpleChanges, inject } from '@angular/core';
import { coerceBoolean } from '@util/functions/objects';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-sliding-content',
  templateUrl: './sliding-content.component.html',
  styleUrls: ['./sliding-content.component.scss']
})
export class SlidingContentComponent implements OnInit, OnChanges, AfterViewInit {

  private _animationInitDelay = -1;

  @HostBinding('class')
  get clazzes(): string[] {
    return [
      this.expandedBehaviorSubject.value ? 'expanded' : 'collapsed',
      this.blockBehaviorSubject.value ? 'block' : 'popup'
    ];
  }

  protected renderContent$ = new BehaviorSubject(false);
  protected _keepRenderingContent = false;

  private expandedBehaviorSubject = new BehaviorSubject<boolean>(false);
  private blockBehaviorSubject = new BehaviorSubject<boolean>(false);
  private _animationSpeed = '200ms';


  private elementRef = inject(ElementRef);
  private ngZone = inject(NgZone);

  @Input()
  set keepRendering(value: boolean) {
    this._keepRenderingContent = coerceBoolean(value);
  }

  @Input()
  set expanded(value: boolean) {
    this.expandedBehaviorSubject.next(coerceBoolean(value));

    if (!this.keepRendering) {
      // if the sliding content is about to open then render content immidiately (equalize immidiately)
      if (value === true) {
        this.equalizeRenderContentStateWithExpandedStateSavelyAfter(0);
      } else {
        // else wait for the animation to end
        const t = parseInt(this.animationSpeed, 10) || 200;
        this.equalizeRenderContentStateWithExpandedStateSavelyAfter(t);
      }
    }

  }

  @Input()
  set block(value: boolean) {
    this.blockBehaviorSubject.next(coerceBoolean(value));
  }

  @Input()
  set animationInitDelay(value: number) {
    this._animationInitDelay = value;
  }

  /**
   * css string value to refer to a duration
   */
  get animationSpeed() {
    return this._animationSpeed;
  }

  @Input()
  set animationSpeed(value: string) {
    this._animationSpeed = value;
    this.setAnimationSpeedToNewValueandReturnOld(value);
  }

  constructor() {}

  ngOnInit(): void {
    this.animationSpeed = this.setAnimationSpeedToNewValueandReturnOld('0ms');
  }

  ngOnChanges(changes: SimpleChanges): void {
    // get the first change (init value) of expanded and set renderContent accordingly
    if (changes?.['expanded']?.firstChange && this.renderContent$.value !== changes['expanded'].currentValue) {
      this.renderContent$.next(changes['expanded'].currentValue);
    }
  }

  ngAfterViewInit(): void {
    if (this._animationInitDelay >= 0) {
      setTimeout(() => this.setAnimationSpeedToNewValueandReturnOld(this.animationSpeed), this._animationInitDelay);
    } else {
      this.setAnimationSpeedToNewValueandReturnOld(this.animationSpeed);
    }
  }

  private setAnimationSpeedToNewValueandReturnOld(speed: string): string {
    const host = this.elementRef.nativeElement as HTMLElement;
    const oldValue = window.getComputedStyle(host)?.getPropertyValue('--animationSpeed');
    host?.style?.setProperty('--animationSpeed', speed);
    return oldValue;
  }

  private equalizeRenderContentStateWithExpandedStateSavelyAfter(ms = 0) {
    if (this.expandedBehaviorSubject.value !== this.renderContent$.value) {

      const equalizeFn = () => {
        this.ngZone.run(() => {
          if (this.expandedBehaviorSubject.value !== this.renderContent$.value) {
            this.renderContent$.next(this.expandedBehaviorSubject.value);
          }
        });
      };

      if (ms === 0) {
        equalizeFn();
      } else {
        this.ngZone.runOutsideAngular(() => setTimeout(() => equalizeFn(), ms));
      }

    }
  }

}
